body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F4F4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  text-align: center;
}


.test {
  transition: .4s;
  }
  .test:hover { 
    color: #F0DEC8 !important; 
    text-decoration: underline;
    text-decoration-color: #F0DEC8;
 }
  .thercard {
    transition: .4s;
    }
    .thercard:hover {
    transition: .4s;
    background-color: rgba(142,160,164, 0.1) ;
    /* border-radius: 100%; */
    }


.carousel-item{
  width: 100%; /*width you want*/
  height: 500px; /*height you want*/
  overflow: hidden;
}
.carousel-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#g_a11y_announcement{
  height: 0 !important;
  width: 0 !important;
}


.ther-grid {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: .5rem ;
  }

@media (min-width: 640px) { 
  .ther-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem ;
    }
} 

@media (min-width: 1024px) { 
  .ther-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem ;
    }
} 

@media (min-width: 1280px) { 
  .ther-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem ;
    }
} 

@media screen and (min-width: 1536px) { 
  .ther-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem ;
    }
} 


iframe{
  display: inline !important;
} 


